import React from "react";
import { graphql } from "gatsby";

import useSetLocale from "../hooks/useSetLocale";
import Layout from "../components/Layout/Layout";

import Hero from "../components/Hero/Hero";
import About from "../components/About/About";
import History from "../components/History/History";
import FreshOcean from "../components/FreshOcean/FreshOcean";
import BigPicture from "../components/BigPicture/BigPicture";
import SeeSalmon from "../components/SeeSalmon/SeeSalmon";

import Seo from "../components/seo";

const IndexPage = ({ data }) => {
  // useSetLocale();
  // let page = data.allSanityHomepage.edges.map(edge => edge.node).filter(node => node.title === 'Forside')[0];

  useSetLocale();

  const filteredData = data.allSanityHomepage.edges
    .map(edge => edge.node)
    .filter(node => node.title === "Forside");

  let page = null;

  if (filteredData && filteredData[0]) {
    page = filteredData[0];
  }

  // console.log("page : ", page);

  return (
    <Layout>
      <Seo title="Home" />
      {page && page.hero && (
        <Hero text={page.hero.heroText} image={page.hero.heroBackground} />
      )}
      {page && page.om && (
        <About images={page.om.images} text={page._rawOm.content} />
      )}
      {page && page.history && (
        <History
          images={page.history.historyImages}
          text={page._rawHistory.historyText}
        />
      )}
      {page && page.fresh && (
        <FreshOcean
          text={page._rawFresh.content}
          image={page.fresh.image}
          stats={page.fresh.stats}
        />
      )}
      {page && page.bigPicture && <BigPicture image={page.bigPicture} />}
      {page && page.seeSalmon && (
        <SeeSalmon
          image={page.seeSalmon.images}
          text={page._rawSeeSalmon.content}
        />
      )}
    </Layout>
  );
};

export default IndexPage;

export const query = graphql`
  {
    allSanityHomepage {
      edges {
        node {
          title
          bigPicture {
            asset {
              fluid(maxWidth: 1920) {
                ...GatsbySanityImageFluid_noBase64
              }
            }
            video {
              asset {
                _key
                _type
                status
                assetId
                playbackId
                filename
                thumbTime
              }
            }
            attribution
            alt
          }
          fresh {
            stats {
              text
              number
            }
            image {
              alt
              video {
                asset {
                  _key
                  _type
                  status
                  assetId
                  playbackId
                  filename
                  thumbTime
                }
              }
              attribution
              asset {
                fluid(maxWidth: 780) {
                  ...GatsbySanityImageFluid_noBase64
                }
              }
            }
          }
          hero {
            heroText
            heroBackground {
              alt
              asset {
                fluid: fluid(maxWidth: 1980) {
                  ...GatsbySanityImageFluid_noBase64
                }
                mobile: fluid(maxHeight: 610) {
                  ...GatsbySanityImageFluid_noBase64
                }
              }
              attribution
              video {
                asset {
                  _key
                  _type
                  status
                  assetId
                  playbackId
                  filename
                  thumbTime
                }
              }
            }
          }
          history {
            historyImages {
              _key
              alt
              asset {
                fluid(maxWidth: 780) {
                  ...GatsbySanityImageFluid_noBase64
                }
              }
              video {
                asset {
                  _key
                  _type
                  status
                  assetId
                  playbackId
                  filename
                  thumbTime
                }
              }
              attribution
            }
          }
          om {
            images {
              asset {
                fluid(maxWidth: 780) {
                  ...GatsbySanityImageFluid_noBase64
                }
              }
              alt
              attribution
              video {
                asset {
                  _key
                  _type
                  status
                  assetId
                  playbackId
                  filename
                  thumbTime
                }
              }
            }
          }
          seeSalmon {
            images {
              attribution
              alt
              asset {
                fluid(maxWidth: 780) {
                  ...GatsbySanityImageFluid_noBase64
                }
              }
              video {
                asset {
                  _key
                  _type
                  status
                  assetId
                  playbackId
                  filename
                  thumbTime
                }
              }
            }
          }
          _rawHistory(resolveReferences: { maxDepth: 0 })
          _rawFresh(resolveReferences: { maxDepth: 0 })
          _rawOm(resolveReferences: { maxDepth: 0 })
          _rawSeeSalmon(resolveReferences: { maxDepth: 0 })
        }
      }
    }
  }
`;
